import base from './base'
import merge from 'lodash/merge'
import { PAGE_CONTEXT as p } from 'Constants'

export default merge(base, {
  name: 'od',
  group: 'plain',
  useMainMenu: true,
  favouritesEnabled: false,
  preloadCategoryImages: true,
  apiKey: 'X5jUyVubhnJCXkqS22AHbFxWEwJdzAsEk3G2snm3zSNmEfZ5',
  siteVerifyCode: 'ViOMKahq_p4xPDjXVW5aoDNENXnHNU8C3wHnDOFdbPo',
  useEmbedDemo: true,
  interStitialEnabled: false,
  altClickrefEnabled: true,
  articlesEnabled: true,
  trendsEnabled: false,
  instalooksEnabled: false,
  hubsEnabled: false,
  useBreadcrumbs: false,
  compareCategoryLinksEnabled: true,
  articlesOnProductListPage: false,
  showStoreNameOnProductCard: true,
  robotsText: true,
  showCookiePolicy: false,
  useScrollButton: true,
  hubEnabled: false,
  heroEnabled: true,
  heroImage: {
    small:
      'https://ik.imagekit.io/sqhmihmlh/pexels-jacob-sierra-15113597.jpg?tr=w-1203,h-500,q-50',
    large:
      'https://ik.imagekit.io/sqhmihmlh/pexels-jacob-sierra-15113597.jpg?tr=w-1203,h-500,q-50'
  },
  siteWide: {
    searchPlaceholderText: 'Suche nach Elementen',
    useHomeIcon: true,
    extraLinks: [
      {
        to: 'ARTICLE_LIST',
        label: 'Stories',
        children: '/article/',
        type: p.ARTICLE
      }
    ]
  },
  contentListSkins: {
    homeInstalooks: 'Instalooks',
    instalook: 'Instalooks',
    instalookList: 'Instalooks',
    homeTrends: 'Trend',
    trendList: 'Trend',
    smallArticle: 'smallArticle',
    hubTrend: 'Trend',
    productInstalooks: 'Instalooks'
  },
  productList: {
    slots: {
      useContentListItem: true
    }
  },
  articles: {
    relatedLabel: 'Neueste Artikel',
    listHeading: 'Style Journal',
    listSubHeading:
      'Alles, was Sie jetzt über Mode und Lifestyle wissen müssen.',
    homeArticles: {
      title: 'Neueste Style-Geschichten',
      intro: 'Alles, was Sie jetzt über Mode und Lifestyle wissen müssen',
      limit: 12,
      showMore: 'Alle ansehen'
    },
    article: {
      title: 'Style Journal',
      intro: 'Alles, was Sie jetzt über Mode und Lifestyle wissen müssen',
      limit: 4,
      showMore: 'Alle ansehen'
    },
    productPage: {
      title: 'Style Journal',
      intro: 'Alles, was Sie heute über Mode und Lifestyle wissen müssen',
      limit: 4,
      showMore: 'Alle ansehen'
    }
  },
  locale: 'de',
  instalooks: {
    homeInstalooks: {
      title: 'Celebrity Get The Look',
      showMore: 'Alle ansehen'
    },
    instalook: {
      limit: 5
    },
    productPage: {
      title: 'Shop by Celebrity',
      intro:
        'See it. Like it. Shop it. Discover the latest celebrity-inspired looks, as seen on the ’gram.',
      limit: 6,
      showMore: 'Alle ansehen'
    }
  },
  article: {
    showTaggedCategory: true,
    showSponsoredBy: true,
    item: {
      image: {
        showExtraInfo: true
      }
    },
    useSectionLink: false
  },
  sorting: {
    title: 'Sortieren nach Preis',
    lowLabel: 'Niedrig bis hoch',
    highLabel: 'Absteigend',
    productListTitle: 'Sortieren nach Preis'
  },
  helmet: {
    partner: 'Octer',
    defaultDescription:
      'OCTER ist ein einzigartiger Marktplatz der größten Geschäfte, in denen Sie Abteilungen einkaufen oder im Geschäft einkaufen können. Von Mode über Geräte vergleichen wir sofort Tausende von Marken und zeigen Ihnen die nächsten Spiele. Sie erhalten also immer den perfekten Stil und Preis und entdecken Produkte aus Geschäften, die Sie zuvor noch nicht berücksichtigt hatten.',
    metaTitleFormat: {
      [p.HOME]: 'Einkaufen | {{PARTNER_NAME}}',
      [p.PRODUCT_LIST]: 'Durchsuche | {{PARTNER_NAME}}',
      [p.ARTICLE_LIST]: 'Neueste Shopping-Artikel | {{PARTNER_NAME}}',
      [p.COMPARE]: '{{PRODUCT_TITLE}} | Vergleichen | {{PARTNER_NAME}}'
    },
    articles: {
      title: 'Neueste Artikel',
      description:
        'Sehen Sie sich die neuesten Shopping-Artikel von Octer DE an, um zu sehen, was im Trend liegt'
    }
  },
  home: {
    panels: [
      {
        uuid: '22b3cf9f84e0',
        name: 'Damen',
        slug: 'damen',
        description: '',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/https:/ik.imagekit.io/sqhmihmlh/octer/kinga-cichewicz-3McEVKL4-bg-unsplash.jpg',
          large:
            'https://ik.imagekit.io/sqhmihmlh/https:/ik.imagekit.io/sqhmihmlh/octer/kinga-cichewicz-3McEVKL4-bg-unsplash.jpg'
        }
      },
      {
        uuid: '0b74ea8ebaf4',
        name: 'Herren',
        slug: 'herren',
        description: '',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/https:/ik.imagekit.io/sqhmihmlh/octer/zahir-namane-TjUJJACTav4-unsplash.jpg',
          large:
            'https://ik.imagekit.io/sqhmihmlh/https:/ik.imagekit.io/sqhmihmlh/octer/zahir-namane-TjUJJACTav4-unsplash.jpg'
        }
      },
      {
        uuid: '7c8a153fed14',
        name: 'Haus & Garten',
        slug: 'haus-und-garten',
        description: '',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/https:/ik.imagekit.io/sqhmihmlh/octer/allie-smith-qJXC-BlAWJY-unsplash.jpg',
          large:
            'https://ik.imagekit.io/sqhmihmlh/https:/ik.imagekit.io/sqhmihmlh/octer/allie-smith-qJXC-BlAWJY-unsplash.jpg'
        }
      },
      {
        uuid: '7f22d645fbdc',
        name: 'Sport',
        slug: 'sport',
        description: '',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/octer/nav-panel-categories/01_Sports_Cycling.jpg',
          large:
            'https://ik.imagekit.io/sqhmihmlh/octer/nav-panel-categories/tr:h-723,w-700/01_Sports_Cycling.jpg'
        }
      }
    ],
    useStoreTitle: false,
    categories: {
      heading: 'Einkaufen nach Kategorie',
      linkLable: 'Alle durchsuchen'
    }
  },
  filters: {
    sale: {
      label: 'Nur Verkaufsartikel',
      checkbox: false
    },
    topLevelCategories: false,
    colors: {
      enabled: true
    },
    navPanel: {
      triggerText: 'Filtern & sortieren'
    },
    price: {
      title: 'Preisklasse',
      belowLable: 'Unter',
      aboveLable: 'Über',
      currencySymbol: '€'
    },
    extendedStore: {
      placeholder: 'Suchladen'
    }
  },
  appliedFilters: {
    title: 'Angewandte Filter',
    clearText: 'Clear all'
  },
  searchResults: {
    label: 'Ihre Suchergebnisse für:'
  },
  buyButton: {
    label: 'Jetzt kaufen',
    lowStockLabel: 'Wenig Lagerbestand - jetzt kaufen',
    useBuyButtonIcon: true
  },
  routes: {
    [p.TERMS]: {
      page: 'TermsAndConditionsPage',
      path: `${process.env.ANT_PREFIX}/TermsAndConditions`
    }
  },
  comparePage: {
    titlePrefix: 'Weitere Übereinstimmungen mit Bezug zu: '
  },
  compareLinkLabel: 'Weitere Treffer',
  pagination: {
    instalookList: 16,
    productList: 32,
    home: 8,
    altProducts: 4,
    compare: 23,
    hubList: 3
  },
  relatedArticles: {
    articleCard: false
  },
  articleCard: {
    withExtraInfo: true,
    withSubTitle: true,
    image: {
      small: {
        width: '310',
        height: '400'
      },
      medium: {
        width: '430',
        height: '400'
      },
      large: {
        width: '310',
        height: '225'
      }
    }
  },
  instalookCard: {
    image: {
      small: {
        width: '660',
        height: '600'
      },
      medium: {
        width: '430',
        height: '400'
      },
      large: {
        width: '310',
        height: '225'
      }
    }
  },
  shopPath: process.env.ANT_PREFIX,
  compareTitle: 'Ihnen könnte auch gefallen...',
  descriptionLabel: 'Produktinformation'
})
